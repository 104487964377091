@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 412px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 640px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1024px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1280px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1366px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1440px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1439px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1365px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1279px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1023px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 767px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 639px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 411px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: "Verdana", sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

html {
  position: relative;
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1280px) {
  html {
    font-size: 14px;
  }
}

*, :after, :before {
  box-sizing: inherit;
}

a {
  color: #eebe81;
}

a:hover {
  text-decoration: none;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid #aaa;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.error-view {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #eee;
  background-image: url("pic/bg_view-section--newbuilding.jpg");
  background-size: 100%;
  color: #898a8b;
}

.error-view__block {
  text-align: left;
  width: 90%;
  max-width: 580px;
  margin: 1rem auto;
}

.error-view__code {
  font-weight: bold;
  font-size: 8rem;
  margin-right: 2rem;
  line-height: .92;
  text-align: right;
  text-shadow: 0 1px 5px #2d4053;
  color: #f2c481;
}

@media only screen and (min-width: 1280px) {
  .error-view__code {
    font-size: 12rem;
  }
}

.error-view__msg {
  margin-top: -2em;
  background-color: #fff;
  border-top: 4px solid #daa767;
  padding: 2.5rem 4rem 3.5rem;
  line-height: 1.4;
  box-shadow: 10.8px 28px 54px rgba(0, 0, 0, 0.24);
}

@media only screen and (min-width: 1280px) {
  .error-view__msg {
    margin-top: -3rem;
    padding: 2rem;
    line-height: 1.4;
  }
}

.error-view__msg ul, .error-view__msg ol, .error-view__msg p {
  margin: .65em 0;
}

.error-view__msg strong {
  font-size: 1.8em;
  color: #000;
}

.error-view__msg ul, .error-view__msg ol {
  padding-left: 1.5rem;
}

.error-view__msg small {
  display: inline;
}

.error-view__msg .logo {
  background: #212e38;
  padding: 5%;
  margin-top: 5%;
  text-align: center;
}

.error-view__msg .logo a {
  display: inline-block;
  vertical-align: middle;
}
