////
/// @group error.scss
////


// Config
// ================================
	@import 'config';


// Фикс mqpacker
// ================================
	@include mqPacker;


// Normalize
// ================================

	@include normalize__document;
	@include normalize__sections;
	// @include normalize__grouping-content;
	// @include normalize__text-level-semantics;
	// @include normalize__embedded-content;
	// @include normalize__forms;
	// @include normalize__interactive;
	// @include normalize__scripting;
	// @include normalize__hidden;

	// @include normalize__text-level-semantics__psevdo-classes;
	// @include normalize__forms__psevdo-classes;
	// @include normalize__addons;



// Import Partials
// ================================
	@import '_partials/base';



// Custom css
// ================================
	.error-view {
		width: 100%;
		height: 100%;
		text-align: center;
		background: #eee;
		background-image: url('pic/bg_view-section--newbuilding.jpg');
		background-size: 100%;
		color: #898a8b;

		&__block {
			text-align: left;
			width: 90%;
			max-width: 580px;
			margin: 1rem auto;
		}

		&__code {
			font-weight: bold;
			font-size: 8rem;
			margin-right: 2rem;
			line-height: .92;
			text-align: right;
			text-shadow: 0 1px 5px #2d4053;
			color: #f2c481;


			@include media( $lg ) {
				font-size: 12rem;
			}
		}
	}

	.error-view__msg {
		margin-top: -2em;
		background-color: #fff;
		// box-shadow: 0 3px 10px rgba(#000, .2);
		// padding: 1em 2em;
		border-top: 4px solid #daa767;
		padding: 2.5rem 4rem 3.5rem;
		line-height: 1.4;
		box-shadow: 10.8px 28px 54px rgba(0, 0, 0, 0.24);	

		@include media( $lg ) {
			margin-top: -3rem;
			padding: 2rem;
			line-height: 1.4;
		}

		ul, ol, p {
			margin: .65em 0;
		}

		strong {
			font-size: 1.8em;
			color: #000;
		}

		ul, ol {
			padding-left: 1.5rem;
		}

		small {
			display: inline;
		}

		.logo {
			background: #212e38;
			padding: 5%;
			margin-top: 5%;
			text-align: center;
			a {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

